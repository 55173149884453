import "../styles/login.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError(null);
  };

  const verContraseña = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.email || !formData.password) {
      setError("Por favor, completa todos los campos");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_URL_API}/auth/login`, {
        email: formData.email,
        password: formData.password,
      })
      .then((response) => {
        const data = JSON.stringify(response.data);
        localStorage.setItem("user", data);

        if (response.data.isFirstLogin) {
          navigate("/password");
        } else {
          navigate("/products");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (
            err.response.status === 404 ||
            err.response.status === 401 ||
            err.response.status === 400
          ) {
            setError("Credenciales inválidas");
          } else {
            setError("Ocurrió un error, intente más tarde");
          }
        }
      });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form-login">
        <div className="title-form">
          <h2>Login</h2>
        </div>

        <div className="spaces-form">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            name="email"
            required
            onChange={handleInputChange}
            value={formData.email}
          />
        </div>

        <div className="spaces-form">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            name="password"
            required
            onChange={handleInputChange}
            value={formData.password}
          />
          <i className="fa-solid fa-eye" onClick={verContraseña}></i>
        </div>

        {error && <span className="alert-login">{error}</span>}

        <div className="button-container">
          <button type="submit" className="button-login">
            Log in
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
