import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/newPassword.css";
const NewPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setError(null);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!newPassword || !confirmPassword) {
      setError("Debes ingresar y confirmar la nueva contraseña");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Las contraseñas no coinciden");
      return;
    }

    const formatData = JSON.parse(localStorage.getItem("user"));
    try {
      await axios.post(
        `${process.env.REACT_APP_URL_API}/auth/change-password`,
        {
          email: formatData.email,
          newPassword: newPassword,
        },
      );

      setSuccessMessage("Contraseña cambiada exitosamente");
      setNewPassword("");
      setConfirmPassword("");
      setError(null);

      navigate("/products");
    } catch (error) {
      setError("Hubo un error al cambiar la contraseña. Intente nuevamente.");
      navigate("/");
    }
  };
  return (
    <div className="password-container">
      <div className="title-form-password">
        <h2>Cambiar contraseña</h2>
      </div>

      <form onSubmit={handleSubmit} className="form-container-password">
        <div className="form-group-password">
          <label htmlFor="newPassword">Nueva contraseña</label>
          <input
            type={showNewPassword ? "text" : "password"}
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={handleNewPasswordChange}
            required
          />
          <i
            className={`fa-solid ${showNewPassword ? "fa-eye-slash" : "fa-eye"}`}
            id="eye-password"
            onClick={() => setShowNewPassword(!showNewPassword)}
          ></i>
        </div>

        <div className="form-group-password">
          <label htmlFor="confirmPassword">Confirmar nueva contraseña</label>
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
          <i
            className={`fa-solid ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"}`}
            id="eye-password"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          ></i>
        </div>

        {error && <span className="alert alert-danger">{error}</span>}
        {successMessage && (
          <span className="alert alert-success">{successMessage}</span>
        )}
        <button type="submit" className="btn btn-primary">
          Cambiar
        </button>
      </form>
    </div>
  );
};
export default NewPassword;
