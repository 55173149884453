import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/newProducts.css";

const NewProduct = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const formatData = JSON.parse(localStorage.getItem("user"));
    if (!formatData || !formatData.token) {
      navigate("/");
      return;
    }

    axios
      .get(`${process.env.REACT_APP_URL_API}/validate`, {
        headers: {
          Authorization: `Bearer ${formatData.token}`,
        },
      })
      .catch(() => navigate("/"));
  }, [navigate]);

  const buttonNewProduct = (event) => {
    event.preventDefault();

    if (!image || !title || !description || !price) {
      setError("Debes completar todos los campos.");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    formData.append("name", title);
    formData.append("description", description);
    formData.append("price", price);

    axios
      .post(`${process.env.REACT_APP_URL_API}/products`, formData)
      .then(() => {
        navigate("/products");
      })
      .catch(() => {
        setError("Hubo un error al agregar el producto. Intente más tarde.");
      });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <button className="button-back" onClick={handleBackClick}>
        Volver
      </button>

      <div className="form-container-newproducts">
        <div className="title-newproduct">
          <h3>Agregar nuevo producto</h3>
        </div>
        <form className="form-newproduct">
          <div className="form-img">
            <label htmlFor="image">Adjuntar imagen</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              required
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          <div className="form-spaces">
            <label htmlFor="title">Titulo</label>
            <input
              type="text"
              id="title"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="form-spaces">
            <label htmlFor="description">Descripción</label>
            <textarea
              type="text"
              id="description"
              required
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="form-spaces">
            <label htmlFor="price">Precio</label>
            <input
              type="text"
              id="price"
              required
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="button-container">
            <button className="button-newproduct" onClick={buttonNewProduct}>
              Agregar nuevo producto
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewProduct;
