import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../styles/products.css";

const Products = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [editingInfo, setEditingInfo] = useState(null);
  const [editFields, setEditFields] = useState({
    id: "",
    name: "",
    description: "",
    price: "",
    image: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API}/products`,
        );
        setData(response.data);
      } catch (err) {
        console.error("Error fetching products:", err);
        navigate("/");
      }
    };

    const formatData = JSON.parse(localStorage.getItem("user"));
    if (!formatData || !formatData.token) {
      navigate("/");
      return;
    }

    axios
      .get(`${process.env.REACT_APP_URL_API}/validate`, {
        headers: {
          Authorization: `Bearer ${formatData.token}`,
        },
      })
      .catch(() => {
        navigate("/");
      });

    fetchProducts();
  }, [navigate]);

  const fetchProducts = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/products`)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setError("Hubo un error al cargar los productos. Intente más tarde.");
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDeleteClick = (id) => {
    setProductToDelete(id);
    setShowConfirmModal(true);
  };

  const confirmDeleteProduct = () => {
    axios
      .delete(`${process.env.REACT_APP_URL_API}/products/${productToDelete}`)
      .then(() => {
        setData((prevData) =>
          prevData.filter((product) => product.id !== productToDelete),
        );
        setShowConfirmModal(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setError("ID incorrecto");
        } else if (err.response && err.response.status === 500) {
          setError("Se produjo un error. Intente más tarde.");
        } else {
          setError("Hubo un error al eliminar el producto. Intente más tarde.");
        }
        setShowConfirmModal(false);
      });
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleSaveEdit = (updatedProduct) => {
    const formData = new FormData();
    for (const key in updatedProduct) {
      if (updatedProduct[key] !== null) {
        formData.append(key, updatedProduct[key]);
      }
    }

    axios
      .put(
        `${process.env.REACT_APP_URL_API}/products/${updatedProduct.id}`,
        formData,
      )
      .then(() => {
        fetchProducts();
        setEditingInfo(null);
        setValidationErrors({});
      })
      .catch(() => {
        setError("Hubo un error en modificar los datos. Intente más tarde.");
      });
  };

  const handleEditClick = (item) => {
    setEditingInfo(item);
    setEditFields(item);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFields((prev) => ({
      ...prev,
      [name]: name === "image" ? e.target.files[0] : value,
    }));
  };

  const handleSaveClick = () => {
    const errors = {};

    if (!editFields.name.trim()) {
      errors.name = "Debes completar este campo";
    }
    if (!editFields.description.trim()) {
      errors.description = "Debes completar este campo";
    }
    if (!editFields.price) {
      errors.price = "Debes completar este campo";
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      handleSaveEdit(editFields);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <div className="logout-button">
        <button onClick={handleLogout}>Cerrar sesión</button>
      </div>
      <div className="products-container">
        <h2>Productos</h2>
        {error && <div className="error-message">{error}</div>}

        <div className="link-product">
          <Link to="/products/new">Agregar nuevo producto</Link>
        </div>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Imágen</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="tbody-tables">
              {data.length
                ? data.map((p) => (
                    <tr key={p.id}>
                      <td>
                        {editingInfo && editingInfo.id === p.id ? (
                          <>
                            <input
                              type="file"
                              name="image"
                              onChange={handleInputChange}
                            />
                            {validationErrors.image && (
                              <div className="error-message">
                                {validationErrors.image}
                              </div>
                            )}
                          </>
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_URL_WEB}/images/${p.image}`}
                            alt={p.name}
                            onClick={() =>
                              handleImageClick(
                                `${process.env.REACT_APP_URL_WEB}/images/${p.image}`,
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </td>
                      <td>
                        {editingInfo && editingInfo.id === p.id ? (
                          <>
                            <input
                              type="text"
                              name="name"
                              value={editFields.name}
                              onChange={handleInputChange}
                            />
                            {validationErrors.name && (
                              <div className="error-message">
                                {validationErrors.name}
                              </div>
                            )}
                          </>
                        ) : (
                          p.name
                        )}
                      </td>
                      <td id="description">
                        {editingInfo && editingInfo.id === p.id ? (
                          <>
                            <input
                              type="text"
                              name="description"
                              value={editFields.description}
                              onChange={handleInputChange}
                            />
                            {validationErrors.description && (
                              <div className="error-message">
                                {validationErrors.description}
                              </div>
                            )}
                          </>
                        ) : (
                          p.description
                        )}
                      </td>
                      <td>
                        {editingInfo && editingInfo.id === p.id ? (
                          <>
                            <input
                              type="text"
                              name="price"
                              value={editFields.price}
                              onChange={handleInputChange}
                            />
                            {validationErrors.price && (
                              <div className="error-message">
                                {validationErrors.price}
                              </div>
                            )}
                          </>
                        ) : (
                          p.price
                        )}
                      </td>
                      <td className="delete-container">
                        {editingInfo && editingInfo.id === p.id ? (
                          <i
                            className="fa-solid fa-check"
                            onClick={handleSaveClick}
                          ></i>
                        ) : (
                          <>
                            <i
                              className="fa-solid fa-pen"
                              onClick={() => handleEditClick(p)}
                            ></i>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleDeleteClick(p.id)}
                            ></i>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>

      {showConfirmModal && (
        <div className="modal-overlay">
          <div className="modal-content-delete">
            <p>¿Estás seguro de que quieres borrar este producto?</p>
            <button className="confirm-button" onClick={confirmDeleteProduct}>
              Sí
            </button>
            <button className="cancel-button" onClick={handleCancelDelete}>
              No
            </button>
          </div>
        </div>
      )}

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Imagen grande" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
