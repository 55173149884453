import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import NewPassword from "./pages/NewPassword";
import Products from "./pages/Products";
import NewProduct from "./pages/NewProduct";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/password" element={<NewPassword />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/new" element={<NewProduct />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
